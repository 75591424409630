@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");

:root {
    --header-height: 3rem;
    --nav-width: 50px;
    --first-color: #bcbcbc;
    --second-color: rgb(185, 165, 166);
    --third-color: rgb(70, 58, 60);
    --first-color-light: #afa5d9;
    --active-color: #02cc7c;
    --active-bg-color: #eeffec;
    --white-color: #fff;
    --body-font: "Nunito", sans-serif;
    --normal-font-size: 1rem;
    --z-fixed: 100;
    --sidebar-width: 80px;
    --edit-color: #3859e2;
    --top-offset: 63px;
    --border-color: #e0e5eb;
}

main {
    max-width: 100vw;
    overflow: hidden;
}

.c-editor ::after {
    box-sizing: border-box;
}

.c-editor body {
    font-family: 'Montserrat', sans-serif;
    position: relative;
    margin: var(--header-height) 0 0 0;
    padding: 0 1rem;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    transition: 0.5s;
}

.c-editor a {
    text-decoration: none;
}

a, .list-group-item a {
    color: #000;
}

.c-editor .header {
    width: 100%;
    height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background-color: var(--white-color);
    z-index: var(--z-fixed);
    transition: 0.5s;
    border-bottom: 1px solid #e0e5eb;
}

.c-editor .header_toggle {
    color: var(--first-color);
    font-size: 1.5rem;
    cursor: pointer;
}

.c-editor .header_img {
    width: 40px;
    height: 40px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
}

.c-editor .header_img img {
    width: 40px;
}

.card {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; 
    border-radius: 0;
}

.adjusted-card {
    border-radius: 12px;
    margin-bottom: 10px;
    padding: 10px;
}

.accordion-body{
    padding: 9px;
}

/* Default side menu colors */

.wbp-editor .offcanvas {
    top: var(--top-offset);
    background-color: var(--white-color) !important;
    border-top: 1px solid var(--border-color);
}

.c-editor .l-navbar {
    position: fixed;
    left: 0;
    top: var(--top-offset);
    width: var(--sidebar-width);
    height: 100vh;
    /*background-image: linear-gradient(to bottom right, rgba(153,204,68,.9), rgba(74,201,71,.9));*/
    background-color: #fff;
    color: #2d3034;
    transition: 0.5s;
    z-index: var(--z-fixed);
    box-shadow: 5px 0 5px -5px rgba(0,0,0,0.3);
    border-top: 1px solid var(--border-color);
}

/* Orange side menu colors */

.tpl-orange .c-editor .l-navbar {
    /* removed x=y overrides */
    background-image: linear-gradient(to bottom right, #b9681c, #ee832290);
}

.c-editor .nav {
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.c-editor .nav_link {
    display: inline-block;
    padding: 0px 0;
}

.c-editor .nav_name {
    display: none;
}

.c-editor .nav_logo {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: 0.5rem 0 0.5rem 1.5rem;
    margin-bottom: 2rem;
}

.c-editor .nav_logo-icon {
    font-size: 1.25rem;
    color: var(--white-color);
}

.c-editor .nav_logo-name {
    color: var(--white-color);
    font-weight: 700;
}

.c-editor .nav_link {
    position: relative;
    color: var(--first-color-light);
    transition: 0.3s;
}

.c-editor .nav_link:hover {
    color: var(--white-color);
}

.c-editor .nav_icon {
    font-size: 1.25rem;
}

.fa-white {
    color: #fff;
}

.fa-white:hover {
    color: #d36c9a;
}

.c-editor .body-pd {
    padding-left: calc(var(--nav-width) + 1rem);
}

.c-editor .active {
    color: var(--white-color);
}

/*
.c-editor .active::before {
    content: "";
    position: absolute;
    left: 0;
    width: 2px;
    height: 32px;
    background-color: var(--white-color);
}
*/

.l-navbar .active .left-menu-box {
    color: var(--active-color);
    background-color: var(--active-bg-color);
}

.c-editor .height-100 {
    height: 100vh;
}

.header-preview-nav {
    padding: 0 10px;
    background-color: var(--white-color);
    box-shadow: 0px 5px 5px -5px rgba(0,0,0,0.3);
}

.sidebar-expanded .wbp-editor-wrapper, .sidebar-expanded .wbp-editor-header, .sidebar-expanded .wbp-editor-footer {
    margin-right: calc(var(--nav-width) + 256px);
}

.form-control {
    background-color: #fff;
    color: #000;
    border: 0;
    border-radius: 0;
}

.form-control:focus {   
    outline: none !important;
    background-color:#fff;
    color: #000;
}


.left-menu-box1 {
    background-color: #edeaee;
    padding: 7px 10px 7px 10px;
    margin-left: -10px;
}

.left-menu-box1:hover {
    background-color: #bdcbf5;
    color: #3859e2;
}


.left-menu-box2 {
    background-color: #edeaee;
    color: #68667c;
    padding: 7px 8px 6px 10px;
    margin-left: -10px;
}


.left-menu-box4 {
    background-color: #edeaee;
    color: #68667c;
    padding: 8px 11px 8px 11px;
    margin-left: -10px;
}


    .left-menu-box2:hover {
        background-color: #bdcbf5;
        color: #3859e2;
    }

.left-menu-box {
    color: #2d3034;
    padding: 8px 15px;
    border-radius: 50%;
    font-size: 24px
}

    .left-menu-box:hover {
        background-color: #bdcbf5;
        color: #3859e2;
    }

.form-control {
    border-radius: 0;
}

.c-editor .com-com {
    position: relative;
}

.com-com .com-edit-link a {
    position: absolute;
    right: 20px;
    top: 5px;
    background-color: var(--edit-color);
    padding: 3px 8px;
    border-radius: 5px;
    color: var(--white-color);
}

.editortop {
    margin-left: 45px;
    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.editorsidbar-spcr {
    margin-top: -10px;
    margin-bottom: 14px;
    border-bottom: 1px solid #c2c2c2;
}

.accordion {
    --bs-accordion-color: rgb(70, 58, 60);
    --bs-accordion-bg: var(--first-color);
    --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
    --bs-accordion-border-color: #878787;
    --bs-accordion-border-width: 1px;
    --bs-accordion-border-radius: 0.375rem;
    --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: #000;
    --bs-accordion-btn-bg: var(--bs-accordion-bg);
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%230c63e4%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    --bs-accordion-btn-focus-border-color: var(--first-color-light);
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    /*--bs-accordion-active-color: #3859e2;
    --bs-accordion-active-bg: var(--first-color-light);*/
    --bs-accordion-active-color: rgb(70, 58, 60);
    --bs-accordion-active-bg: #c1c1c1;
}


.accordion-button {
    background-color: #fff;
}


.accordion-collapse {
    background-color: #ececec !important;
}

.form-control {
    box-shadow: rgba(99, 99, 99, 0.2)0px 2px 8px 0px;
}

.c-editor .offcanvas-body {
    overflow-y: scroll;
}


@media screen and (max-width: 767px) {
    .c-editor body {
        margin: calc(var(--header-height) + 1rem) 0 0 0;
        padding-left: calc(var(--nav-width) + 2rem);
    }

    .c-editor .header {
        height: calc(var(--header-height) + 1rem);
        padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
    }

    .c-editor .header_img {
        width: 40px;
        height: 40px;
    }

    .c-editor .header_img img {
        width: 45px;
    }


}

@media screen and (min-width: 768px) {
    .c-editor body {
        margin: calc(var(--header-height) + 1rem) 0 0 0;
        padding-left: calc(var(--nav-width) + 2rem);
    }

    .c-editor .header {
        height: calc(var(--header-height) + 1rem);
        padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
    }

    .c-editor .header_img {
        width: 40px;
        height: 40px;
    }

    .c-editor .header_img img {
        width: 45px;
    }


    .c-editor .offcanvas-body {
        padding: 0;
    }

    .c-editor .offcanvas.show {
        width: calc(var(--nav-width) + 256px);
        margin: 0;
    }

    .c-editor .body-pd {
        padding-left: calc(var(--nav-width) + 188px);
    } 
}


.editorMenuSubtxt {
    font-size: 10px;
    margin-bottom: 10px;
}


.pagesSelect {
    padding: 10px 20px 10px 20px;
    border-radius: 0;
}

.pagesSelect:hover {
    background-color: #FCF1DD;
}

.btn-success {
    background-color: #50be4d;
    border: none;
}