.AdminLayout-nav {
    width: 100%;
    box-shadow: 0px 5px 5px -5px rgba(0,0,0,0.3);
}

.AdminLayout-sidebar {
    position: fixed;
    top: 63px;
    bottom: 0;
    width: 280px;
    margin-top: -7px;
    z-index: 999;
    background-color: var(--bs-white);
    box-shadow: 5px 0 5px -5px rgba(0,0,0,0.3);
}

.AdminLayout-sidebar svg {
    width: 20px;
}

.AdminLayout-sidebar .sidebar-title {
    text-transform: uppercase;
    padding: 12px 10px;
    font-size: 11px;
    letter-spacing: .05rem;
    font-weight: 600;
    color: var(--bs-gray-600);
}

.AdminLayout-sidebar .nav-link span {
    margin-left: 10px;
}

.AdminLayout-sidebar, .AdminLayout-sidebar .nav-link {
    color: var(--bs-gray-700);
}

.AdminLayout-sidebar, .AdminLayout-sidebar .nav-link:hover {
    color: var(--bs-black);
}

.AdminLayout-sidebar .nav-link.active {
    color: var(--bs-black);
    background-color: transparent;
    font-weight: 600;
}

.AdminLayout-content {
    margin-bottom: 100px;
}

@media (min-width: 768px) {
    .AdminLayout-nav {
        width: 100%;
        z-index: 9;
    }

    .AdminLayout-sidebar {
        position: fixed;
        z-index: 10;
    }

    .AdminLayout-content {
        margin-left: 280px;
    }
}